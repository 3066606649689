<template>

  <div class="app_cp">
    <div class="gujiap"><p><span @click="back()">产品信息</span> > <span style="color: rgb(41 76 177)">商品详情</span></p></div>
    <div class="detailshead">
      <div class="boximg">
        <img :src="str1[0].img" alt="">
      </div>
      <div class="boxtext">
        <h2>{{str1[0].commodityName}}</h2>
        <div class="boxdec">
          <div>
            <p>批准文号：{{str1[0].commodityApproval}}</p>
            <p>生产厂家：{{str1[0].commoditymanufactor}}</p>
            <p>剂型：{{str1[0].commodityMeter}}</p>
          </div>
          <div>
            <p>中包装：{{str1[0].commodityMedium}}</p>
            <p>件装量：{{str1[0].commodityNumber}}</p>
            <p>近远效期：{{str1[0].commodityTime}}</p>
          </div>
        </div>
        <div class="clickImg">
          <div v-for="(item,index) in str1[0].imgList" :key="index">
            <img :src="item.src" alt="" @click="checkimg(index)">
          </div>
        </div>
      </div>
    </div>
    <div class="detailsbody">
      <div class="detailsbodysms">
        <div>
          商品说明书
        </div>
      </div>
      <div>
        <img style="width: 1200px;" :src="str1[0].commodityImg" alt="">
      </div>
    </div>

  </div>

</template>

<script setup>
import { useRoute,useRouter } from 'vue-router';
import { onBeforeMount, reactive } from 'vue';
const route = useRoute();
const router = useRouter();

let str = reactive({
  list: [{
    id:"xb",
    commodityName: "硝苯地平缓释片(Ⅱ)(伲福达) 20mg*30片", // 商品名称
    commoditySpecifications: "20mg*30片", // 商品规格
    commodityApproval: "国药准字H10910052", // 商品批准文号
    commodityMedium: "10 瓶 可拆零", // 商品中包装
    commoditymanufactor: "青岛黄海制药有限责任公司", // 商生产厂家
    commodityNumber: "600 瓶", // 商品件数量
    commodityMeter: "片剂", // 商品剂型
    commodityTime: "2024.09.25 / 2025.05.05", // 商品效期
    commodityImg: require("@/assets/details/a1xbdp.png"), // 商品说明书图片
    img: require("@/assets/details/detailsfont/xb1.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/xb1.png") },
      { src: require("@/assets/details/detailsfont/xb2.png") },
      { src: require("@/assets/details/detailsfont/xb3.png") }
    ]
  }, {
    id:"xg",
    commodityName: "桂林西瓜霜 (三金) 3.5g",
    commoditySpecifications: "3.5g",
    commodityApproval: "国药准字Z45021599",
    commodityMedium: "10 盒 可拆零",
    commoditymanufactor: "桂林三金药业股份有限公司",
    commodityNumber: "360 盒",
    commodityMeter: "散剂",
    commodityTime: "2025.11.25 / 2026.01.08",
    commodityImg: require("@/assets/details/a2glxgs.png"),
    img: require("@/assets/details/detailsfont/xg1.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/xg1.png") },
      { src: require("@/assets/details/detailsfont/xg2.png") },
      { src: require("@/assets/details/detailsfont/xg3.png") }
    ]
  }, {
    id:"tb",
    commodityName: "头孢克肟颗粒(达力芬)(无糖) 50mg*12包 无糖型",
    commoditySpecifications: "50mg*12包 无糖型",
    commodityApproval: "国药准字H20020512",
    commodityMedium: "5 盒 可拆零",
    commoditymanufactor: "国药集团致君(深圳)制药有限公司",
    commodityNumber: "210 盒",
    commodityMeter: "颗粒剂",
    commodityTime: "2025.01.31 / 2025.02.28",
    commodityImg: require("@/assets/details/a3tb.png"),
    img: require("@/assets/details/detailsfont/tb1.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/tb1.png") }
    ]
  }, {
    id:"yb",
    commodityName: "腰痹通胶囊(康缘) 0.42g*100粒",
    commoditySpecifications: "0.42g*100粒",
    commodityApproval: "国药准字Z20010045",
    commodityMedium: "1 盒 不可拆零",
    commoditymanufactor: "江苏康缘药业股份有限公司",
    commodityNumber: "160 盒",
    commodityMeter: "胶囊剂",
    commodityTime: "2025.02.28 / 2025.02.28",
    commodityImg: require("@/assets/details/a4ybtjn.png"),
    img: require("@/assets/details/detailsfont/yb1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/yb1.jpg") },
      { src: require("@/assets/details/detailsfont/yb2.jpg") },
      { src: require("@/assets/details/detailsfont/yb3.jpg") }
    ]
  }, {
    id:"cy",
    commodityName: "肠炎宁片 0.42g*12s*4板 薄膜衣",
    commoditySpecifications: "0.42g*12s*4板 薄膜衣",
    commodityApproval: "国药准字Z36020518",
    commodityMedium: "10 盒 可拆零",
    commoditymanufactor: "江西康恩贝中药有限公司",
    commodityNumber: "200 盒",
    commodityMeter: "无",
    commodityTime: "2024.12.31 / 2024.12.31",
    commodityImg: require("@/assets/details/a5cylp.png"),
    img: require("@/assets/details/detailsfont/cyn1.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/cyn1.png") }
    ]
  }, {
    id:"bq",
    commodityName: "贝前列素钠片 (凯那) 40ug*10片",
    commoditySpecifications: "40ug*10片",
    commodityApproval: "国药准字H20083589",
    commodityMedium: "10 盒 可拆零",
    commoditymanufactor: "北京泰德制药股份有限公司",
    commodityNumber: "400 盒",
    commodityMeter: "片剂(薄膜衣片)",
    commodityTime: "2025.09.26 / 2025.09.26",
    commodityImg: require("@/assets/details/a6bls.png"),
    img: require("@/assets/details/detailsfont/bq1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/bq1.jpg") },
      { src: require("@/assets/details/detailsfont/bq2.jpg") },
      { src: require("@/assets/details/detailsfont/bq3.jpg") },
      { src: require("@/assets/details/detailsfont/bq4.jpg") }
    ]
  }, {
    id:"ys",
    commodityName: "盐酸坦索罗辛缓释胶囊 (康恩贝/必坦/前列) 0.2mg*14粒",
    commoditySpecifications: "0.2mg*14粒",
    commodityApproval: "国药准字H20050285",
    commodityMedium: "10 盒 可拆零",
    commoditymanufactor: "杭州康恩贝制药有限公司",
    commodityNumber: "200 盒",
    commodityMeter: "处方药",
    commodityTime: "2025.10.31 / 2025.10.31",
    commodityImg: require("@/assets/details/a7ysts.png"),
    img: require("@/assets/details/detailsfont/ys1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/ys1.jpg") },
      { src: require("@/assets/details/detailsfont/ys2.jpg") },
      { src: require("@/assets/details/detailsfont/ys3.jpg") },
      { src: require("@/assets/details/detailsfont/ys4.jpg") }

    ]
  }, {
    id:"kd",
    commodityName: "坎地沙坦酯分散片 (奥必欣/源瑞制药) 4mg*16片",
    commoditySpecifications: "4mg*16片",
    commodityApproval: "国药准字H20060174",
    commodityMedium: "10 盒 可拆零",
    commoditymanufactor: "昆明源瑞制药有限公司",
    commodityNumber: "300 盒",
    commodityMeter: "片剂",
    commodityTime: "2023.07.31 / 2024.09.30",
    commodityImg: require("@/assets/details/a8kdst.png"),
    img: require("@/assets/details/detailsfont/kd1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/kd1.jpg") },
      { src: require("@/assets/details/detailsfont/kd2.jpg") },
      { src: require("@/assets/details/detailsfont/kd3.jpg") }
    ]
  }, {
    id:"yy",
    commodityName: "医用退热贴(儿童用温和型) 50mm*110mm*2片*2袋",
    commoditySpecifications: "50mm*110mm*2片*2袋",
    commodityApproval: "皖合械备20170016号",
    commodityMedium: "1 盒 可拆零",
    commoditymanufactor: "合肥小林日用品有限公司",
    commodityNumber: "48 盒",
    commodityMeter: "无",
    commodityTime: "2025.12.15 / 2025.12.15",
    commodityImg: require("@/assets/details/a9yytst.jpg"),
    img: require("@/assets/details/detailsfont/ts1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/ts1.jpg") }
    ]
  }, {
    id:"zy500",
    commodityName: "紫外线空气消毒机 XSY-500A",
    commoditySpecifications: "XSY-500A",
    commodityApproval: "",
    commodityMedium: "1 优炜芯科技有限公司  可拆零",
    commoditymanufactor: "武汉优炜芯科技有限公司",
    commodityNumber: "0 优炜芯科技有限公司",
    commodityMeter: "",
    commodityTime: "2023.08.03 / --",
    commodityImg: require("@/assets/details/a10xdj.jpg"),
    img: require("@/assets/details/detailsfont/xdj1.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/xdj1.png") }
    ]
  }, {
    id:"zy400",
    commodityName: "紫外线空气消毒机 XSG-400A",
    commoditySpecifications: "XSG-400A",
    commodityApproval: "",
    commodityMedium: "1 优炜芯科技有限公司 可拆零",
    commoditymanufactor: "武汉优炜芯科技有限公司",
    commodityNumber: "0 优炜芯科技有限公司",
    commodityMeter: "",
    commodityTime: "2023.08.03 / --",
    commodityImg: require("@/assets/details/a10xdj.jpg"),
    img: require("@/assets/details/detailsfont/xdj400.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/xdj400.png") }
    ]
  }, {
    id:"dp",
    commodityName: "多潘立酮片 (吗丁啉) 10mg*21s*2板 R",
    commoditySpecifications: "10mg*21s*2板 R",
    commodityApproval: "国药准字H10910003",
    commodityMedium: "10 盒 可拆零",
    commoditymanufactor: "桂林三金药业股份有限公司",
    commodityNumber: "360 盒",
    commodityMeter: "片剂",
    commodityTime: "2027.07.31 / 2027.11.30",
    commodityImg: require("@/assets/details/a12dplt.png"),
    img: require("@/assets/details/detailsfont/dpl1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/dpl1.jpg") },
      { src: require("@/assets/details/detailsfont/dpl2.jpg") },
      { src: require("@/assets/details/detailsfont/dpl3.jpg") },
      { src: require("@/assets/details/detailsfont/dpl4.jpg") }
    ]
  }, {
    id:"yymq",
    commodityName: "医用棉签 12cm 50支*20袋",
    commoditySpecifications: "12cm 50支*20袋",
    commodityApproval: "赣械注准20172640175",
    commodityMedium: "1 包 不可拆零",
    commoditymanufactor: "南昌卫材医疗器械有限公司",
    commodityNumber: "60 包",
    commodityMeter: "",
    commodityTime: "2026.06.02 / 2026.07.22",
    commodityImg: require("@/assets/details/a12yymq.jpg"),
    img: require("@/assets/details/detailsfont/yymq1.jpg"),
    imgList: [
      { src: require("@/assets/details/detailsfont/yymq1.jpg") },
      { src: require("@/assets/details/detailsfont/yymq2.jpg") },
      { src: require("@/assets/details/detailsfont/yymq3.jpg") }
    ]
  }, {
    id:"xyjts",
    commodityName: "血压计 台式",
    commoditySpecifications: "台式",
    commodityApproval: "苏械注准20152070945",
    commodityMedium: "1 台 不可拆零",
    commoditymanufactor: "江苏鱼跃医疗设备股份有限公司",
    commodityNumber: "10 台",
    commodityMeter: "",
    commodityTime: "2050.12.31 / 2050.12.31",
    commodityImg: require("@/assets/details/a13xyjts.jpg"),
    img: require("@/assets/details/detailsfont/xyjts.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/xyjts.png") }
    ]
  }, {
    id:"dzxyj",
    commodityName: "电子血压计 HEM-7135",
    commoditySpecifications: "HEM-7135",
    commodityApproval: "辽械注准20192070081",
    commodityMedium: "1 台 不可拆零",
    commoditymanufactor: "欧姆龙(大连)有限公司",
    commodityNumber: "10 台",
    commodityMeter: "",
    commodityTime: "9999.12.31 / 9999.12.31",
    commodityImg: require("@/assets/details/a14xyjdz.jpg"),
    img: require("@/assets/details/detailsfont/dzxyj.png"),
    imgList: [
      { src: require("@/assets/details/detailsfont/dzxyj.png") }
    ]
  }],
})
let str1 = reactive([])

onBeforeMount(() => {
  str.list.forEach((item) => {
    if (item.id == route.query.id) {
      str1.push(item)
    }
  })
})
function checkimg (index) {
  str1[0].imgList.forEach((item, ind) => {
    if (index == ind) {
      str1[0].img = item.src
    }
  })
}
function back(){
  router.push({
    path: `/commodity`
  })
}

</script>

<style scoped lang="less">
.app_cp {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  .gujiap {
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #f5f5f5;
    margin-bottom: 20px;
    p{
       width: 1200px;
       margin: 0 auto;
    }
    p:hover{
      cursor: pointer;
    }
  }
  .detailshead {
    display: inline-block;
    display: flex;
    .boximg {
      width: 290px;
      height: 290px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border: 1px solid #d9d9d9;
    }
    img {
      width: 191px;
      height: 231px;
      transition: all 0.3s ease;
    }
    img:hover {
      transform: scale(1.1);
    }
    .boxtext {
      height: 290px;
      width: 841px;
      margin-left: 30px;
      .boxdec {
        width: 881px;
        height: 134px;
        margin: 40px 0px 80px 0px;
        display: flex;
        background: #f8f8f8;
        p {
          margin: 20px 120px 0px 10px;
        }
      }
      .clickImg {
        width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
        div {
          display: flex;
          margin: 0px 10px;
          border: 1px solid #d9d9d9;
        }
        div:hover {
          border: 1px solid #666666;
        }
        img {
          width: 40px;
          height: 40px;
        }
        img:hover {
          transform: scale(1);
        }
      }
    }
  }
  .detailsbody {
    margin: 20px 0px;
    .detailsbodysms {
      width: 1200px;
      height: 40px;
      margin-bottom: 20px;
      background: #f1f2f4;
      border: 1px solid #eeeeee;
      div {
        width: 99px;
        height: 41px;
        color: #1ab580;
        line-height: 41px;
        text-align: center;
        border-top: 2px solid #1ab580;
        background: #ffffff;
      }
    }
  }
}
</style>
