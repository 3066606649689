<template>
  <div class="app_cp">
    <div class="content">
        <div class="banner">
            <div class="banner_left">
                <div class="item" @click="goProduct('1')">中西成药</div>
                <div class="item" @click="goProduct('2')">医疗器械</div>
            </div>
            <Carousel class="banner_mid"></Carousel>
            <div class="banner_right">
                <div>公告</div>
                <p class="title" @click="showNews">国家药监局关于注销腹腔镜...</p>
            </div>
        </div>
    </div>
    <div class="product-box">
        <h2 class="title">传统医药</h2>
        <div class="item-box">
            <img @click="goProduct('1')" class="logo-img" src="https://images.expowh.com/expowh-b2b/prod/20240716/4706574d3245494a9fd88d99fc3e9da3.jpg" alt="">
            <div class="item" :class="`item${index}`" v-for="(item, index) in list" :key="index" @click="shopDetails(item.id)">
                <img class="img" :src="item.img" alt="">
                <div class="text">
                    <h5>{{item.title}}</h5>
                </div>
                <div class="text">
                    <p>
                        <span class="chang">厂</span>
                        <span class="Pspan">{{item.manufactor}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>

import { ref, reactive, toRefs, getCurrentInstance } from "vue"
import { useRouter } from 'vue-router'
import Carousel from '@/components/common/Carousel.vue'

let str1 = reactive({
  list: [{
     id: "xb",
    title: "硝苯地平缓释片(Ⅱ)(伲福达) 20mg *30片",
    manufactor: "青岛黄海制药有限责任公司",
    img: require("@/assets/home/a1.png")
  },
  {
    id: "xg",
    title: "桂林西瓜霜 (三金) 3.5g",
    manufactor: "桂林三金药业股份有限公司",
    img: require("@/assets/home/a2.png")
  },
  {
    id: "tb",
    title: "头孢克肟颗粒(达力芬)(无糖) 50mg*12包 无糖型",
    manufactor: "深圳致君制药",
    img: require("@/assets/home/a3.png")
  },
  {
     id: "yb",
    title: "腰痹通胶囊(康缘) 0.42g*100粒",
    manufactor: "江苏康缘药业",
    img: require("@/assets/home/a4.png")
  },
  {
    id: "cy",
    title: "肠炎宁片 0.42g*12s*4板 薄膜衣",
    manufactor: "江西康恩贝中药有限公司",
    img: require("@/assets/home/a5.png")
  },
  {
     id: "bq",
    title: "贝前列素钠片 (凯那) 40ug*10片",
    manufactor: "北京泰德制药股份有限公司",
    img: require("@/assets/home/a6.png")
  },
  {
    id: "ys",
    title: "盐酸坦索罗辛缓释胶囊 0.2mg*7s*2板",
    manufactor: "江苏恒瑞医药股份有限公司",
    img: require("@/assets/home/a7.jpg")
  }
],
  text: [{
    title: "药房信息",
    text: "武汉健博会大药房有限公司整合全国乃至全球大健康产业资源，服务健康中国战略。未利用好健博会资源，更好的为人民身体健康服务，于2022年09月20号正式成立，主要经营药品、食品、医疗器械及大健康相关产品。"
  },
  {
    title: "产品经营信息",
    text: "武汉健博会大药房主经营品种主要以药品、医疗器械产品为主。"
  }]
})
let { list, text } = toRefs(str1)

const router = useRouter()


function shopDetails (id) {
  router.push({
    path: `details`,
    query: { id }
  })
}

function showNews () {
  router.push({
    path: `news`,
  })
}

function goProduct (type) {
  router.push({
    path: `commodity`,
    query: {
        type
    }
  })
}
</script>

<style scoped lang="less">

.content {
	width: 1200px;
	margin: 0 auto;
	margin-bottom: 30px;
	.banner {
        margin-top: 10px;
		margin-bottom: 17px;
		display: flex;
		justify-content: space-between;
        background: #F4F5F7;
		.banner_left {
			width: 188px;
			height: 410px;
			background: #333;
            color: #fff;
            
            .item {
                height: 40px;
                line-height: 40px;
                padding-left: 20px;
                cursor: pointer;
                &:hover {
                    background: #fff;
                    color: #1ABF50;
                }
            }
		}
		.banner_mid {
			width: 766px;
			height: 410px;
			background: #f2f7ff;
			border-radius: 3px;
		}
		.banner_right {
            box-sizing: border-box;
            padding: 10px;
			width: 226px;
			height: 410px;
			background: #ffffff;
			border-radius: 3px;
            cursor: pointer;
            .title {
                font-size: 14px;
                &:hover {
                    color: #1ABF50;
                }
            }
		}
	}
}


.app_cp {
  background: #F4F5F7;
}

.product-box {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px;
    
    .title {
        color: #333;
        margin-bottom: 30px;

        &:before {
            content: '';
            display: block;
            float: left;
            width: 6px;
            height: 25px;
            background: #F2990D;
            margin-top: 4px;
            margin-right: 10px;
        }
    }
    .item-box {
        display: flex;
        flex-wrap: wrap;
        background: #fff;
        
        .logo-img {
            width: 720px;
            height: 310px;
            cursor: pointer;
        }
        .item {
            padding: 10px 15px;
            box-sizing: border-box;
            width: 240px;
            height: 310px;
            border-right: 1px solid #eee;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            gap: 10px;
            .img {
                margin-top: 15px;
                width: 172px;
                height: 172px;
                transition: all .3s ease;
                &:hover {
                    transform: scale(1.05);
                }
            }
            .text {
                text-align: left;
                h5 {
                    align-self: flex-start;
                    margin: 7px 0px;
                    width: 208px;
                    height: 40px;
                    line-height: 20px;
                    font-size: 14px;
                    -webkit-line-clamp: 2;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:hover {
                        color: #1ABF50;
                    }
                }
                p {
                    margin: 0px;
                    padding-left: 5px;
                    font-size: 13px;
                    display: flex;
                    .chang {
                    width: 18px;
                    height: 15px;
                    text-align: center;
                    background-color: #b4c8ff;
                    margin-right: 5px;
                    }
                    .Pspan {
                    width: 185px;

                    -webkit-line-clamp: 1;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    }
                }
                }
        }
        .item0, .item1{
            border-bottom: 1px solid #eee;
        }
        :nth-child(3),:nth-child(8) {
            border-right: none;
        }
        
    }
   
}
</style>
