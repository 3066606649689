<template>
	<div class="footer">
		<div class="qcode">
			<div style="margin-left: 114px">
				<img src="@/assets/index/qcode1.jpg" class="img" />
				<div class="text">扫一扫关注公众号</div>
			</div>
			<div style="margin-left: 72px">
				<img src="@/assets/index/qcode2.jpg" class="img" />
				<div class="text">扫一扫添加平台客服</div>
			</div>
			<div class="msg1">
				<div>投诉举报电话：136-5983-1900 （微信同号）</div>
				<div style="margin-top: 15px">客服电话：136-5983-1900 （微信同号）</div>
				<div style="margin-top: 15px; font-size: 14px">
					咨询时间 8:30 — 17:30 周一至周五 法定节假日除外
				</div>
			</div>
		</div>
		<div class="msg2">
			监管机构： 国家食品药品监管局 湖北省食品药品监管局
			<span class="link"></span>
			经营地址：武汉市汉阳区龙兴西街5号九州通总部大厦1号楼31楼3103-01<br />
			<a
				href="https://b2b.expowh.com//static/html/picturePreview.html?info=1768575686187253762,https://images.expowh.com"
				target="_blank"
				>营业执照</a
			><span class="link"></span
			><a
				href="https://b2b.expowh.com//static/html/picturePreview.html?info=1768578137087868929,https://images.expowh.com"
				target="_blank"
				>增值电信经营许可证 鄂B2-20190525</a
			><span class="link"></span
			><a
				href="https://b2b.expowh.com/static/html/picturePreview.html?info=1768576368332079106,https://images.expowh.com"
				target="_blank"
				>互联网药品信息服务资格证书</a
			><span class="link"></span
			><a
				href="https://beian.mps.gov.cn/#/query/webSearch?code=42010502001214"
				target="_blank"
				>鄂公网安备42010502001214号</a
			><span class="link"></span
			><a
				href="https://b2b.expowh.com/static/html/picturePreview.html?info=1768579578355580930,https://images.expowh.com"
				target="_blank"
				>《药品网络交易第三方平台备案凭证》编号:(鄂)网药平台备字〔2023〕<br />第000004-000号</a
			><span class="link"></span
			><a
				href="https://b2b.expowh.com/static/html/picturePreview.html?info=1768579848610349057,https://images.expowh.com"
				target="_blank"
				>医疗器械网络交易服务第三方平台备案凭证
				备案编号：（鄂）网械平台备字〔2019〕第00004号</a
			><span class="link"></span
			><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
				>鄂 ICP备19026137号-1</a
			>
			<br /><span
				>Copyright© 2019 expowh.com 武汉市世界大健康博览会线上平台管理有限公司
				版权所有</span
			><br /><a href="https://www.hbwhexpo.com/" target="_blank">世界大健康博览会</a>
		</div>
	</div>
</template>
<script setup></script>
<style scoped lang="less">
.footer {
	width: 1200px;
	margin: 0 auto;
	padding-top: 24px;
	padding-bottom: 18px;
	.qcode {
		display: flex;
		align-items: center;
		padding-bottom: 27px;
		border-bottom: 1px solid #ddd;
		.img {
			width: 125px;
			height: 125px;
		}
		.text {
			font-size: 13px;
			color: #666666;
			line-height: 14px;
			margin-top: 6px;
			text-align: center;
		}
		.msg1 {
			margin-left: 66px;
			& > div {
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: 400;
				font-size: 20px;
				color: #333333;
			}
		}
	}
	.icon {
		width: 15px;
		height: 15px;
		margin-right: 8px;
	}
	.msg2 {
		text-align: center;
		margin-top: 22px;
		font-size: 12px;
		color: #666;
		line-height: 24px;
	}
	.link:before {
		content: "|";
		display: inline-block;
		color: #333;
		margin-left: 2.5px;
		margin-right: 2.5px;
	}
	a {
		color: #333;
		text-decoration: none;
		&:hover {
			color: #1ab580;
		}
	}
}
</style>
