<template>
	<div class="app_cp">
		<div class="gujiap">
			<p>
				<span @click="back()">首页</span> >
				<span style="color: rgb(41 76 177)">公告详情</span>
			</p>
		</div>
		<div class="detail-box">
			<div class="detail-title">
				国家药监局关于注销腹腔镜高频手术器械等2个医疗器械注册证书的公告（2024年第99号）
			</div>
			<div class="detail-info">
				<span>发布时间：2024-08-07</span>
				<table>
					<tbody style="margin: 0px; padding: 0px">
						<tr
							style="
								margin: 0px;
								padding: 0px;
								list-style: none;
								border-collapse: collapse;
							"
							class="firstRow"
						>
							<td
								style="
									margin: 0px;
									padding: 4px 0px 0px;
									list-style: none;
									border-collapse: collapse;
								"
							>
								<img
									alt="下载"
									id="btnDown"
									src="https://www.nmpa.gov.cn/wbppimages/download.gif"
								/>&nbsp;<img
									alt="打印"
									id="btnPrint"
									src="https://www.nmpa.gov.cn/wbppimages/print.gif"
								/>
							</td>
							<td
								style="
									margin: 0px;
									padding: 0px;
									list-style: none;
									border-collapse: collapse;
								"
							>
								&nbsp;&nbsp;&nbsp;&nbsp;<a
									target="_blank"
									href="http://v.t.sina.com.cn/share/share.php?title=%E5%9B%BD%E5%AE%B6%E8%8D%AF%E7%9B%91%E5%B1%80%E5%85%B3%E4%BA%8E%E6%B3%A8%E9%94%80%E8%85%B9%E8%85%94%E9%95%9C%E9%AB%98%E9%A2%91%E6%89%8B%E6%9C%AF%E5%99%A8%E6%A2%B0%E7%AD%892%E4%B8%AA%E5%8C%BB%E7%96%97%E5%99%A8%E6%A2%B0%E6%B3%A8%E5%86%8C%E8%AF%81%E4%B9%A6%E7%9A%84%E5%85%AC%E5%91%8A%EF%BC%882024%E5%B9%B4%E7%AC%AC99%E5%8F%B7%EF%BC%89&amp;url=https%3A%2F%2Fwww.nmpa.gov.cn%2Fxxgk%2Fggtg%2Fylqxggtg%2Fylqxqtggtg%2F20240807110719196.html"
									style="
										margin: 0px;
										padding: 0px;
										color: rgb(51, 51, 51);
										text-decoration-line: none;
									"
									><img
										title="分享到新浪微博"
										alt="分享到新浪微博"
										src="https://www.nmpa.gov.cn/wbppimages/share_sina.gif"
									/>&nbsp;</a
								><a
									target="_blank"
									href="http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=https%3A%2F%2Fwww.nmpa.gov.cn%2Fxxgk%2Fggtg%2Fylqxggtg%2Fylqxqtggtg%2F20240807110719196.html&amp;title=%E5%9B%BD%E5%AE%B6%E8%8D%AF%E7%9B%91%E5%B1%80%E5%85%B3%E4%BA%8E%E6%B3%A8%E9%94%80%E8%85%B9%E8%85%94%E9%95%9C%E9%AB%98%E9%A2%91%E6%89%8B%E6%9C%AF%E5%99%A8%E6%A2%B0%E7%AD%892%E4%B8%AA%E5%8C%BB%E7%96%97%E5%99%A8%E6%A2%B0%E6%B3%A8%E5%86%8C%E8%AF%81%E4%B9%A6%E7%9A%84%E5%85%AC%E5%91%8A%EF%BC%882024%E5%B9%B4%E7%AC%AC99%E5%8F%B7%EF%BC%89&amp;api_key="
									style="
										margin: 0px;
										padding: 0px;
										color: rgb(51, 51, 51);
										text-decoration-line: none;
									"
									><img
										title="分享到QQ空间"
										alt="分享到QQ空间"
										src="https://www.nmpa.gov.cn/wbppimages/share_qzone.gif"
									/>&nbsp;</a
								><a
									target="_blank"
									href="https://www.nmpa.gov.cn/wbppcss/ewm.html?url=https%3A%2F%2Fwww.nmpa.gov.cn%2Fxxgk%2Fggtg%2Fylqxggtg%2Fylqxqtggtg%2F20240807110719196.html&amp;title=%E5%9B%BD%E5%AE%B6%E8%8D%AF%E7%9B%91%E5%B1%80%E5%85%B3%E4%BA%8E%E6%B3%A8%E9%94%80%E8%85%B9%E8%85%94%E9%95%9C%E9%AB%98%E9%A2%91%E6%89%8B%E6%9C%AF%E5%99%A8%E6%A2%B0%E7%AD%892%E4%B8%AA%E5%8C%BB%E7%96%97%E5%99%A8%E6%A2%B0%E6%B3%A8%E5%86%8C%E8%AF%81%E4%B9%A6%E7%9A%84%E5%85%AC%E5%91%8A%EF%BC%882024%E5%B9%B4%E7%AC%AC99%E5%8F%B7%EF%BC%89"
									style="
										margin: 0px;
										padding: 0px;
										color: rgb(51, 51, 51);
										text-decoration-line: none;
									"
									><img
										title="分享到微信"
										alt="分享到微信"
										src="https://www.nmpa.gov.cn/wbppimages/share_weixin.gif"
								/></a>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<!-- <div class="detail-download">
            <div
                class="item"
                v-for="(item, index) in newsDetail.detailData.fileList"
                :key="index"
            >
                <img :src="down" alt="" />
                <a :href="preImageUrl + item.fileUrl">{{ item.fileName }}</a>
            </div>
        </div> -->
			<div class="detail-content">
				<p>
					按照《医疗器械监督管理条例》的规定，根据企业申请，国家药品监督管理局现注销以下2家企业共2个产品的医疗器械注册证：
				</p>
				<p>
					一、
					湖南瀚德微创医疗科技有限公司的1个产品：腹腔镜高频手术器械，注册证编号：国械注准20163010651。
				</p>
				<p>
					二、 株式会社常光的1个产品：干式免疫分析仪POCT ANALYZER
					MODEL，注册证编号：国械注进20192222424。
				</p>
				<p>特此公告。</p>
				<p>国家药监局</p>
				<p>2024年8月5日</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

function back() {
	router.push({
		path: `/`,
	});
}
</script>

<style scoped lang="less">
.app_cp {
	background: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 12px;
	.gujiap {
		width: 100%;
		height: 35px;
		line-height: 35px;
		background: #f5f5f5;
		p {
			width: 1200px;
			margin: 0 auto;
		}
		p:hover {
			cursor: pointer;
		}
	}
	.detailshead {
		display: inline-block;
		display: flex;
		.boximg {
			width: 290px;
			height: 290px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 20px;
			border: 1px solid #d9d9d9;
		}
		img {
			width: 191px;
			height: 231px;
			transition: all 0.3s ease;
		}
		img:hover {
			transform: scale(1.1);
		}
		.boxtext {
			height: 290px;
			width: 841px;
			margin-left: 30px;
			.boxdec {
				width: 881px;
				height: 134px;
				margin: 40px 0px 80px 0px;
				display: flex;
				background: #f8f8f8;
				p {
					margin: 20px 120px 0px 10px;
				}
			}
			.clickImg {
				width: 150px;
				height: 50px;
				display: flex;
				align-items: center;
				div {
					display: flex;
					margin: 0px 10px;
					border: 1px solid #d9d9d9;
				}
				div:hover {
					border: 1px solid #666666;
				}
				img {
					width: 40px;
					height: 40px;
				}
				img:hover {
					transform: scale(1);
				}
			}
		}
	}
	.detailsbody {
		margin: 20px 0px;
		.detailsbodysms {
			width: 1200px;
			height: 40px;
			margin-bottom: 20px;
			background: #f1f2f4;
			border: 1px solid #eeeeee;
			div {
				width: 99px;
				height: 41px;
				color: #1ab580;
				line-height: 41px;
				text-align: center;
				border-top: 2px solid #1ab580;
				background: #ffffff;
			}
		}
	}
}
.detail-box {
	width: 1200px;
	margin: 0 auto;
	background: #fff;
	padding: 18px 34px 34px;
	font-weight: 400;
	font-size: 14px;
	.detail-title {
		font-size: 20px;
		color: #333333;
		margin-bottom: 16px;
	}
	.detail-info {
		color: #999999;
		margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 10px;
	}
	.detail-download {
		margin-bottom: 26px;
		color: #4688ff;
		.item {
			display: inline-block;
			margin-right: 10px;
			> img {
				width: 12px;
				height: 12px;
				margin-right: 4px;
			}
			a {
				vertical-align: middle;
			}
		}
	}
	.detail-content {
        font-size: 16px;
		border-top: 1px solid #f6f6f6;
		padding-top: 16px;
		color: #1d1d1d;

		:nth-child(5) {
			margin-top: 50px;
		    padding-left: 770px;
		}
		:nth-child(6) {
			padding-left: 770px;
		}
	}
}
</style>
