const _api = "/wmqe";

const CUST = (n)=>{
  return {
    "excel":_api+"/public/cust/download-excel-model",
    "export":_api+"/public/cust/export",
    "get":_api+"/public/cust/get",
    "opensave":_api+"/public/cust/open/save",
    "save":_api+"/public/cust/save",
    "qr":_api+"/public/cust/req/QrCode",
    "search":_api+"/public/cust/search",
    "submit":_api+"/public/cust/submit",
    "update":_api+"/public/cust/update",
    "upload":_api+"/public/cust/upload",
  }[n]
}

const CUSTOMER = (n)=>{
  return {
    "list":_api+"/customer/exhibitor/list",
  }[n]
}

const LOGIN = (n)=>{
  return {
    "in":_api+"/login",
    "out":_api+"/logout",
  }[n]
}

const LABEL = (n)=>{
  return {
    "export":_api+"/cust/label/export",
    "save":_api+"/cust/label/save",
    "search":_api+"/cust/label/search",
    "list":_api+"/cust/label/list",
    "update":_api+"/cust/label/update/status",
    "re_search":_api+"/cust/label/relation/search",
    "re_list":_api+"/cust/label/relation/list",
    "re_submit":_api+"/cust/label/relation/submit",
    "re_upload":_api+"/cust/label/relation/upload",
    "re_del":_api+"/cust/label/relation/delete",
    "re_export":_api+"/cust/label/relation/export",
    "re_excel":_api+"/cust/label/relation/download-excel-model",
  }[n]
}

const MENU = (n)=>{
  return {
    "user":_api+"/resource/user/menu",
  }[n]
}

const ORG = (n)=>{
  return {
    "add":_api+"/org/add",
    "addDept":_api+"/org/addDept",
    "detail":_api+"/org/detail", //GET
    "edit":_api+"/org/edit", //PUT
    "editDept":_api+"/org/editDept",//
    "orgTree":_api+"/org/orgTree",//GET
    "remove":_api+"/org/remove/",//DELETE
    "removeDept":_api+"/org/removeDept/",//DELETE
    "search":_api+"/org/search",
    "id":_api+"/org/",//GET
  }[n]
}

const REGION = (n)=>{
  return {
    "region":_api+"/region",
    "dyna_tree":_api+"/region/dyna_tree",
  }[n]
}

const ROLE = (n)=>{
  return {
    "list":_api+"/role/list",
    "query":_api+"/role/query",
    "add":_api+"/role/add",
    "disable":_api+"/role/disable",
    "remove":_api+"/role/remove/",
    "update":_api+"/role/update",
    "user":_api+"/role/user/query",
    "id":_api+"/role/",
  }[n]
}

const RESOURCE = (n)=>{
  return {
    "tree":_api+"/resource/tree",
    "roleid":_api+"/resource/role/",
  }[n]
}

const SMSPLAN = (n)=>{
  return {
    "search":_api+"/smsplan/search",
    "sign_search":_api+"/sms/sign/search",
    "sign_detail_search":_api+"/smsplan/detail/search",
    "add":_api+"/smsplan",
    "byid":_api+"/smsplan/",
    "sign_dispatch":_api+"/smsplan/dispatching/detail/search/",
    "export":_api+"/smsplan/exportExcel",
    "export_detail":_api+"/smsplan/detail/exportExcel",
    "import":_api+"/smsplan/import-excel",
    "template":_api+"/smsplan/exportModelExcel",
    
  }[n]
}

const SMSSIGN = (n)=>{
  return {
    "search":_api+"/sms/sign/search", 
    "pass":_api+"/sms/sign/listAllByPass", 
    "export":_api+"/sms/sign/exportExcel", 
    "sync":_api+"/sms/sign/sync",
  }[n]
}
const SMSTEMP= (n)=>{
  return {
    "search":_api+"/sms/template/search", 
    "pass":_api+"/sms/template/listAllByPass", 
    "sync":_api+"/sms/template/sync",
    "export":_api+"/sms/template/exportExcel", 
  }[n]
}

const UPLOAD = (n)=>{
  return {
    "pic":_api+"/dfs/expo:regpic",
    "file":_api+"/dfs/expo-resources",
    "common":_api+"/file/common/upload",
    "byid":_api+"/file/",
  }[n]
}

const USER = (n)=>{
  return {
    "add":_api+"/user/add",
    "info":_api+"/user/login-info",
    "modifypd":_api+"/user/modifypd",
    "org":_api+"/user/org",
    "search":_api+"/user/search",
    "searchBox":_api+"/user/searchBox",
    "reset":_api+"/user/reset",
    "update":_api+"/user/update",
    "useable":_api+"/user/useable",
    "id":_api+"/user/",
  }[n]
}

const VISIT = (n)=>{
  return {
    "add":_api+"/visitor/plan",
    "export":_api+"/visitor/plan/export",
    "list":_api+"/visitor/plan/list",
    "search":_api+"/visitor/plan/search",
    "status":_api+"/visitor/plan/status",
  }[n]
}

const VISITS = (n)=>{
  return {
    "add":_api+"/visitor/summary",
    "export":_api+"/visitor/summary/export",
    "list":_api+"/visitor/summary/list",
    "search":_api+"/visitor/summary/search",
  }[n]
}


const TEST = (n)=>{
  return {
    "menu":_api+"/resource/user/menu",
    "search":_api+"/api/web/mall/goods/allExhibitor",
  }[n]
}


export default {
  CUST,CUSTOMER,
  LOGIN,LABEL,
  MENU,
  ORG,
  REGION,
  ROLE,
  RESOURCE,
  SMSPLAN,
  SMSSIGN,
  SMSTEMP,
  USER,
  UPLOAD,
  VISIT,
  VISITS,
  TEST
}