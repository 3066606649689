import axios from 'axios'
import _loading from './axios-loading.js'

//创建axios的一个实例 
var instance = axios.create({
    //baseURL: __HOST__, //接口统一域名
    timeout:10000, //设置超时
    withCredentials: true,
    credentials:"same-origin",
    headers: {
        'Accept':'*/*',
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8;',
    }
})
instance.all = axios.all;

instance.interceptors.request.use((config) => {
    if(!config.notLoading){
        _loading.showLoading()
    }
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    const token = window.localStorage.getItem('token');

    if(token && !config.noAuth){
        token && (config.headers.Authorization = token)
    }
    //若请求方式为post，则将data参数转为JSON字符串
    if (config.method === 'post') {
        config.data = JSON.stringify(config.data);
    }
    if (config.method === 'get') {
        //config.data = true;
        config.headers["Content-Type"] = 'application/x-www-form-urlencoded;charset=utf-8';
        //config.headers.contentType=config.contentType||'application/x-www-form-urlencoded;charset=utf-8';
    }
    return config;
}, (error) => Promise.reject(error));

instance.interceptors.response.use((response) => {
    if(!response.config.notLoading){
        _loading.hideLoading()
    }
    //响应成功
    //_loading.msg("响应成功", "success");
    if(response.data.hasOwnProperty("code") && response.data.code!=1){
        _loading.msg(response.data.message, "error");
        return Promise.reject(response);
    }
    // if(response.data.hasOwnProperty("repCode") && !response.data.success){
    //     _loading.msg(response.data.repMsg, "error");
    //     return Promise.resolve(response);
    // }

    // QRCODE
    if(response.config.qrcode){
        return Promise.resolve(response);
    }

    if(typeof response.data != "object"){
        _loading.msg(response.data, "error");
        return Promise.reject(response);
    }
    
    return Promise.resolve(response.data);
}, (error) => {
    //console.log(error)
    //响应错误
    _loading.hideLoading()
    if (error.response && error.response.status) {
        const status = error.response.status
        let message=""
        switch (status) {
            case 400:
                message = '请求错误';
                break;
            case 401:
            case 4011:
                message = '未登录';
                location.href="/login.html"
                break;
            case 404:
                message = '请求地址出错';
                break;
            case 500:
                message = '服务器内部错误!';
                break;
            case 503:
                message = '服务不可用!';
                break;
            default:
                message = '请求失败'
        }
        _loading.msg(message);
        return Promise.reject(error);
    }
    return Promise.reject(error);
});


export default {
    all:(opts)=>{
        return instance.all(opts)
    },
    post:(opts)=>{
        if(typeof opts == "string") opts = {url:opts};
        opts.method = "post";
        return instance.request(opts)
    },
    del:(opts)=>{
        if(typeof opts == "string") opts = {url:opts};
        opts.method = "delete";
        return instance.request(opts)
    },
    put:(opts)=>{
        if(typeof opts == "string") opts = {url:opts};
        opts.method = "put";
        return instance.request(opts)
    },
    get:(opts)=>{
        if(typeof opts == "string") opts = {url:opts};
        opts.method = "get";
        return instance.request(opts)
    },
    ..._loading
}
