<template>
	<div class="carousel" @mouseenter="stop()" @mouseleave="start()" v-if="rotationChart.length">
		<div
			v-for="(slide, index) in rotationChart"
			:key="slide.moduleInfoId"
			class="carousel-slide"
			:class="{ active: index === active }"
		>
			<img @click="linkUrl(slide.linkUrl)" :src="slide.imgUrl" />
		</div>
		<ul class="carousel-indicators">
			<li
				v-for="(slide, index) in  rotationChart"
				:key="index"
				:class="{ active: index === active }"
				@click="change(index)"
			></li>
		</ul>
	</div>
</template>
<script setup>
import { ref, watch, onUnmounted } from "vue"
import banner1 from "@/assets/index/banner1.png"
import banner2 from "@/assets/index/banner2.png"
const preImageUrl = 'https://images.expowh.com';

const rotationChart= ref([
  {
    "moduleInfoId": "1791377095052570626",
    "imgUrl": banner1,
    "linkUrl": null,
    "infoOrder": 1,
    "createBy": "admin",
    "createTime": "2024-05-17 15:55:56",
    "updateTime": "2024-08-07 06:26:12"
  },
  {
    "moduleInfoId": "1791377129924014082",
    "imgUrl": banner2,
    "linkUrl": null,
    "infoOrder": 2,
    "createBy": "admin",
    "createTime": "2024-05-17 15:56:05",
    "updateTime": "2024-08-06 07:27:35"
  },
]);


const props = defineProps({
	duration: {
		type: Number,
		default: 3000,
	},
	antoPlay: {
		type: Boolean,
		default: true,
	},
});

let timer = null;
const active = ref(0);
const autoPlayFn = () => {
	clearInterval(timer);
	timer = setInterval(() => {
		active.value++;
		if (active.value >= rotationChart.value.length) {
			active.value = 0;
		}
	}, props.duration);
};

const stop = () => {
	clearInterval(timer);
};

const start = () => {
	if (rotationChart.value.length && props.antoPlay) {
		autoPlayFn();
	}
};
const change = (index) => {
	stop();
	active.value = index;
};
watch(
	() => active.value,
	() => {
		if (rotationChart.value.length && props.antoPlay) {
			autoPlayFn();
		}
	},
	{ immediate: true }
);

const linkUrl = (v) => {
    if (v) window.open(v, '_blank')
}

onUnmounted(() => {
	clearInterval(timer);
});
</script>
<style scoped lang="less">
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #eee
}

.carousel-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.5s;
  opacity: 0;
  transition: opacity 0.5s linear;
  z-index: 1;
  cursor: default;
}
.carousel-slide img {
  width: 100%;
  height: 100%;
}
.carousel-slide.active {
  opacity: 1;
  z-index: 2;
}
.carousel-indicators {
  box-sizing: border-box;
  position: absolute;
  bottom: 25px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 360px;
  z-index: 3;
  cursor: pointer;
  margin-bottom: 0;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 0.5;
  list-style: none
}
.carousel-indicators li.active {
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 1;
}
.carousel_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #FFFFFF;
  line-height: 36px;
  height: 36px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.carousel_bottom ul {
  display: flex;
  justify-content: space-around;
  margin-left: 34px;
  padding: 0;
}
.carousel_bottom li {
  width: 10px;
  height: 10px;
  list-style: none;
  background: #fff;
  border-radius: 50%;
  margin-right: 20px;
  transition: all .3s linear;
  cursor: pointer;
}
.carousel_bottom li.active {
  background: #1A70D3;
}
.carousel_bottom .carousel_msg {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 500;
}
</style>