<template>
  <div class="app_cp">
    <div class="homebanner">
      <img src="@/assets/home/b1.png" alt="">
    </div>
    <div class="homerecommend">
      <div class="homerecommendtop">
        <h3 class="colorhover1" @click="checkinformation(1)">
          中西成药
          <span v-if="stylelook == '1'" class="heng"></span>
        </h3>
        <h3 class="colorhover2" @click="checkinformation(2)">
          医疗器械
          <span v-if="stylelook == '2'" class="heng"></span>
        </h3>
      </div>
      <div class="pl" style="margin-right: -100px;padding-left: 45px;">
        <div class="homerecommendTxtmg" v-for="(item,index) in str3.list" :key="index" @click="shopDetails(item.id)">
          <div class="boximg">
            <img :src="item.img" alt="">
          </div>
          <div class="text">
            <h5>{{item.title}}</h5>
            <p>
              <span class="chang">厂</span>
              <span class="Pspan">{{item.manufactor}}</span>
            </p>
          </div>
        </div>
      </div>

    </div>

    <div class="demo-pagination-block">
      <el-pagination v-model:current-page="currentPage4" v-model:page-size="pageSize4" :page-sizes="[10, 20, 30, 40]" :small="small" :disabled="disabled" :background="background" layout="total, sizes, prev, pager, next, jumper" :total="1" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs } from "vue"
import { useRouter, useRoute } from 'vue-router'

const route = useRoute();

let str1 = reactive({
  list: [{
    id: "xb",
    title: "硝苯地平缓释片(Ⅱ)(伲福达) 20mg *30片",
    manufactor: "青岛黄海制药有限责任公司",
    img: require("@/assets/home/a1.png")
  },
  {
    id: "xg",
    title: "桂林西瓜霜 (三金) 3.5g",
    manufactor: "桂林三金药业股份有限公司",
    img: require("@/assets/home/a2.png")
  },
  {
    id: "tb",
    title: "头孢克肟颗粒(达力芬)(无糖) 50mg*12包 无糖型",
    manufactor: "深圳致君制药",
    img: require("@/assets/home/a3.png")
  },
  {
    id: "yb",
    title: "腰痹通胶囊(康缘) 0.42g*100粒",
    manufactor: "江苏康缘药业",
    img: require("@/assets/home/a4.png")
  },
  {
    id: "cy",
    title: "肠炎宁片 0.42g*12s*4板 薄膜衣",
    manufactor: "江西康恩贝中药有限公司",
    img: require("@/assets/home/a5.png")
  },
  {
    id: "bq",
    title: "贝前列素钠片 (凯那) 40ug*10片",
    manufactor: "北京泰德制药股份有限公司",
    img: require("@/assets/home/a6.png")
  },
  {
    id: "ys",
    title: "盐酸坦索罗辛缓释胶囊 (康恩贝/必坦/前列) 0.2mg*14粒",
    manufactor: "杭州康恩贝制药有限公司",
    img: require("@/assets/home/a7.jpg")
  },
  {
    id: "kd",
    title: "坎地沙坦酯分散片 (奥必欣/源瑞制药) 4mg*16片",
    manufactor: "昆明源瑞制药",
    img: require("@/assets/home/a8.png")
  },
  {
    id: "dp",
    title: "多潘立酮片 (吗丁啉) 10mg*21s*2板",
    manufactor: "西安杨森制药有限公司",
    img: require("@/assets/home/a12.png")
  }]
})
let str2 = reactive({
  list: [
  // {
  //   id: "zy500",
  //   title: "紫外线空气消毒机 XSY-500A",
  //   manufactor: "武汉优炜芯科技有限公司 ",
  //   img: require("@/assets/details/detailsfont/xdj1.png")
  // },
  // {
  //   id: "zy400",
  //   title: "紫外线空气消毒机 XSG-400A",
  //   manufactor: "武汉优炜芯科技有限公司",
  //   img: require("@/assets/details/detailsfont/xdj400.png")
  // },
  {
    id: "yymq",
    title: "医用棉签 12cm 50支*20袋",
    manufactor: "南昌卫材医疗器械有限公司",
    img: require("@/assets/details/detailsfont/yymq1.jpg")
  },
  {
    id: "xyjts",
    title: "血压计 台式",
    manufactor: "江苏鱼跃医疗设备股份有限公司",
    img: require("@/assets/details/detailsfont/xyjts.png")
  },
  // {
  //   id: "dzxyj",
  //   title: "电子血压计 HEM-7135",
  //   manufactor: "欧姆龙(大连)有限公司",
  //   img: require("@/assets/details/detailsfont/dzxyj.png")
  // },
  {
    id: "yy",
    title: "医用退热贴(儿童用温和型) 50mm*110mm*2片*2袋",
    manufactor: "合肥小林日用品有限公司",
    img: require("@/assets/home/a9.png")
  },]
})
let str3 = reactive({
  list: []
})
str3.list = str1.list


const router = useRouter()
function shopDetails (id) {
  router.push({
    path: `details`,
    query: { id }
  })
}

let drug = window.sessionStorage.getItem("drug");
let stylelook = ref(1)

if (drug == '2') {
  stylelook.value = drug
  str3.list = str2.list
}

function checkinformation (index) {
  window.sessionStorage.setItem("drug", index);
  stylelook.value = index
  if (index == '1') {
    str3.list = str1.list
  } else {
    str3.list = str2.list
  }
}

function handleSizeChange (val) {
  console.log(val, "条");
}
function handleCurrentChange (val) {
  console.log(val, "页");
}

if (route.query.type) {
    stylelook.value = route.query.type
}
</script>

<style scoped lang="less">
.app_cp {
  background: #ffffff;
  padding-bottom: 20px;
}
.homebanner {
  img {
    width: 100%;
  }
}
.homerecommendTxtmg:hover {
  cursor: pointer;
}
.homerecommend {
  width: 999px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
  .homerecommendtop {
    text-align: center;
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    .colorhover1 {
      margin-right: 50px;
    }
    // .colorhover1:hover {
    //   color: red;
    // }
    // .colorhover2:hover {
    //   color: red;
    // }
    .heng {
      display: block;
      width: 38px;
      height: 3px;
      background-color: #116d52;
      margin: 3px auto;
    }
  }
  .homerecommendtop:hover {
    cursor: pointer;
  }
  .homerecommendTxtmg {
    width: 156px;
    margin-right: 93px;

    display: inline-block;
    text-align: center;
    margin-top: 20px;
    .boximg {
      width: 119px;
      height: 109px;
      padding: 20px;
      border: 1px solid #d9d9d9;
    }
    img {
      width: 72px;
      height: 87px;
      transition: all 0.3s ease;
    }
    img:hover {
      transform: scale(1.1);
    }
    .text {
      text-align: left;
      h5 {
        margin: 7px 0px;
        width: 156px;
        height: 35px;
        font-size: 12px;
        -webkit-line-clamp: 2;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        margin: 0px;
        font-size: 12px;
        display: flex;
        .chang {
          width: 18px;
          height: 15px;
          text-align: center;
          background-color: #b4c8ff;
          margin-right: 5px;
        }
        .Pspan {
          width: 156px;

          -webkit-line-clamp: 1;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.demo-pagination-block {
  display: flex;
  justify-content: center;
}
</style>
