<template>
  <div class="header">
    <div class="back-home-box">
      <div class="back-home">
        <div class="back-title">欢迎来到大健康平台</div>
      </div>
    </div>
    <div class="main-box">
      <div class="main">
        <img @click="goUrl('/')" class="logo" src="@/assets/index/logo2.png" alt="" />
        <div class="search">
          <div class="input-box">
            <img class="img" src="@/assets/index/sousuo.png" alt="">
            <input class="input" placeholder="请输入商品名称/ERP编码/拼音/厂家" size="large" :bordered="false" :maxlength="100" />
            <div class="btn">搜索</div>
          </div>
          <div class="hot-words" v-if="hotNavCarouselList && hotNavCarouselList.length">
            <span v-for="item in hotNavCarouselList" :key="item" class="hot-words-item">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-menu-box">
      <div class="nav-menu">
        <div class="category">
          全部商品分类
        </div>
        <router-link :to="'/index'" active-class="active">首页</router-link>
        <router-link :to="'/about'" active-class="active">关于我们</router-link>
        <router-link :to="'/commodity'" active-class="active">产品信息</router-link>
        <router-link :to="'/product'" active-class="active">入会合作</router-link>
        <router-link :to="'/contact'" active-class="active">联系我们</router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const emit = defineEmits(['search']);

const props = defineProps({
  keywords: {
    type: String,
    default: ''
  }
});
const hotNavCarouselList = ref(['医用退热贴', '硝苯地平', '阿司匹林肠溶片', '达克宁', '连花清瘟', ])
const searchText = ref(props.keywords);
const goTopHome = () => {
  window.open('https://www.jzyygyl.cn/')
};

const goUrl = (val, params) => {
  const obj = { path: val };
  if (params) obj.query = params
  router.push(obj);
};
</script>
<style scoped lang="less">
.header {
  width: 100%;
  .back-home-box {
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 30px;
    .back-home {
      margin: 0 auto;
      width: 1200px;
      display: flex;
      align-items: center;
      gap: 4px;
      .img {
        display: block;
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
      .back-title {
        color: #898989;
        font-size: 12px;
      }
    }
    .guide {
      font-size: 12px;
      color: #898989;
      cursor: pointer;
      margin-right: 8px;
    }
  }
  .main-box {
    background: #fff;
    .main {
      width: 1200px;
      height: 120px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .logo {
        width: 257px;
        height: 52px;
        margin-right: 69px;
        cursor: pointer;
      }
      .search {
        width: 568px;
        margin-right: 56px;
        position: relative;
        .input-box {
          position: relative;
          height: 42px;
          background: #FFFFFF;
          border-radius: 3px;
          border: 2px solid #1Ab580;
          padding-left: 35px;
          padding-right: 80px;
          .img {
            width: 22px;
            height: 22px;
            position: absolute;
            top: 9px;
            left: 14px;
          }
          .btn {
            width: 80px;
            height: 100%;
            line-height: 40px;
            text-align: center;
            color: #fff;
            letter-spacing: 1px;
            background: #1Ab580;
            position: absolute;
            top: 0;
            right: 0;
            cursor: default;
          }
          .input {
            border: none;
            height: 40px;
            width: 100%;
          }
          .input:focus-visible {
            outline: none;
          }
          .input::-webkit-input-placeholder {
            color: #B3B3B3;
            font-size: 14px;
          }
        }
        .hot-words {
          position: absolute;
          width: 515px;
          line-height: 28px;
          padding: 0 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .hot-words-item {
            padding-right: 10px;
            font-size: 12px;
            color: #959595;
            cursor: default;
            &:hover {
              color: #1Ab580;
            }
          }
        }
      }
      .header-icon-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 12px;
        color: #191919;
        cursor: pointer;
        .header-icon {
          width: 30px;
          height: 30px;
        }
      }
      .cart-box {
        margin: 0 32px 0 37px;
      }
    }
  }
}
.nav-menu-box {
  background: #fff;

  .nav-menu {
    height: 40px;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    color: #1d1d1d;
    display: flex;
    align-items: center;
    gap: 75px;
    .banner_left {
      width: 188px;
      height: 410px;
      background: #ffffff;
      border-radius: 3px;
    }
    .category {
      width: 188px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #1Ab580;
      color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      .category-box {
        display: none;
        position: absolute;
        z-index: 30;
      }
      &:hover .category-box {
        display: block;
      }
    }
    .active {
      color: #1Ab580;
    }
  }
}
</style>