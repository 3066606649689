import { ElLoading,ElMessage,ElMessageBox } from 'element-plus'


let loading;
let requestCount = 0;

const showLoading = (time) => {
  if (requestCount === 0 && !loading) {
      loading = ElLoading.service({
          //text: "加载中...",
          background: 'rgba(0, 0, 0, .5)',
          "customClass": 'global-loading',
      })
  }
  requestCount++;
  if(time){
    setTimeout(()=>{
      loading.close()
      loading = undefined
    },time)
  }
}
const hideLoading = () => {
  requestCount--
  if (requestCount == 0) {
      loading.close()
      loading = undefined
  }
}
const msg = (m , opt = "error") => {
  ElMessage[opt](m);
}
const msgbox = function(opt){
  opt = {...{
    title:"消息提示",
    type:"warning",
    message:"",
    autofocus:false,
    showCancelButton:true,
    customClass:"custom-msgbox",
  },...opt}
  opt.html && (opt.dangerouslyUseHTMLString = true)
  ElMessageBox(opt)
}


export default {
  showLoading,
  hideLoading,
  msg,
  msgbox,
};