<template>
  <div class="app_cp">
    <div class="homebanner">
      <img src="@/assets/b2.png" alt="">
    </div>
    <div class="homemy">
      <div class="message-box">
        <div class="flex msg-input" style="margin-bottom:25px">
          <div class="flex" style="flex:1;">
            <div class="label">姓名</div>
            <input type="text" maxlength="50" class="input" v-model="form.a">
          </div>
          <div class="flex" style="flex:1;">
            <div class="label">邮箱</div>
            <input type="text" maxlength="50" class="input" v-model="form.b">
          </div>
          <div class="flex" style="flex:1;">
            <div class="label">电话</div>
            <input type="text" maxlength="40" class="input" v-model="form.c">
          </div>
        </div>
        <div class="flex msg-input" style="margin-bottom:25px">
          <div class="flex" style="flex:1; align-items: center;">
            <div class="label">类型</div>
            <div class="radio">
              <label for="a1" style="cursor: pointer;margin-bottom: 0;margin-right: 12px;font-size: 14px;">
                <input type="radio" name="type" id="a1" value="1" v-model="form.d">
                企业宣传
              </label>
              <label for="a2" style="cursor: pointer;margin-bottom: 0;margin-right: 12px;font-size: 14px;">
                <input type="radio" name="type" id="a2" value="2" v-model="form.d">
                品种宣传
              </label>
              <label for="a3" style="cursor: pointer;margin-bottom: 0;margin-right: 12px;font-size: 14px;">
                <input type="radio" name="type" id="a3" value="3" v-model="form.d">
                其他类型
              </label>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex" style="flex:1;align-items: flex-start;">
            <div class="label" style="padding-top:12px">内容</div>
            <textarea maxlength="300" type="text" class="textarea" v-model="form.e"></textarea>
          </div>
        </div>
        <div class="m-btn" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import { ref, reactive, toRefs } from "vue"
let str1 = reactive({
  text: [{
      title: "药房信息",
      text: "武汉健博会大药房有限公司整合全国乃至全球大健康产业资源，服务健康中国战略。未利用好健博会资源，更好的为人民身体健康服务，于2022年09月20号正式成立，主要经营药品、食品、医疗器械及大健康相关产品。"
    },
    {
      title: "产品经营信息",
      text: "武汉健博会大药房主经营品种主要以药品、医疗器械产品为主。"
    }
  ]
})
const form = ref({
  a: '',
  b: '',
  c: '',
  d: '1',
  e: ''
})
const submit = () => {
  let flag = true;
  Object.keys(form.value).forEach(n => {
    if (!form.value[n]) {
      flag = false
    }
  });
  if (!flag) {
    return ElMessage({ message: '请填写完整', type: "error" });
  }
  form.value = {
    a: '',
    b: '',
    c: '',
    d: '1',
    e: ''
  }
  ElMessage({ message: '提交成功', type: "success" });
}
</script>
<style scoped lang="less">
.app_cp {
  background: #ffffff;
}
.homebanner {
  img {
    width: 100%;
  }
}
.homemy {
  width: 1080px;
  margin: 0 auto;
  margin-top: -5px;
  padding: 28px 0px;
}
.message-box {
  position: relative;
  width: 960px;
  background: #FFFFFF;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 0 auto;
  top: -125px;
  padding: 50px 60px;
}

.message-box .flex .flex {
  align-items: center;
}
.input {
  width: 236px;
  height: 36px;
  background: #F5F8FA;
  padding-left: 8px;
  border: 0;
  font-size: 14px;
  outline: 0;
}
.textarea {
  border: 0;
  outline: 0;
  width: 882px;
  background: #F5F8FA;
  height: 231px;
  resize: none;
  font-size: 14px;
  padding: 12px 8px;
  margin-bottom: 20px;
}
.label {
  font-size: 14px;
  margin-right: 14px;
}
.m-btn {
  width: 159px;
  height: 41px;
  background: #1Ab580;
  box-shadow: 0px 3px 10px 0px rgba(0, 68, 124, 0.3);
  border-radius: 20px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 41px;
  margin: 0 auto;
  cursor: pointer;
}
.flex {
  display: flex;
}
</style>